import React from 'react';
import './App.css';

function App() {

  return (
    <main>
      <h1>Thulinhred</h1>
    </main>
  );
}

export default App;
